"use client"; 

import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error("Error:", error);
  }, [error]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 text-center">
      <h1 className="display-4 text-danger">¡Ups! Something went wrong</h1>
      <h2 className="mb-3">This is an error</h2>
      <p className="lead">Try refreshing page</p>
      <div className="mt-4">
        <button className="btn btn-primary-outline me-2" onClick={() => reset()}>Refresh</button>
        <a href="/" className="btn btn-link">Let&apos;s go to Home Page</a>
    </div>
  </div>
  );
}
